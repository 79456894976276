import React from 'react';
import Layout from '../components/Layout';
import HeaderMin from '../components/HeaderMin';
import Footer from '../components/Footer';
import pic6 from '../assets/images/pic06.webp';

const IndexPage = () => (
  <Layout>
        <HeaderMin />

    <article className="container box style3">
    <header className="image fit">
        <h1>Is there value in Customer Journey Maps?</h1>
        <p>September 9, 2021</p>
        <img src={pic6} alt="Two people talkong about customer jouney maps" />

        </header>
     
        <section>

<p>Having personally developed many customer journey maps (from both hypotheses and deep customer research) I’m curious why some clients seem to skip over them? Is it because they are uncertain how to use journey maps and therefore unsure how to unlock all the value out of them? Here are some of my thoughts on what they can be used for and why that is valuable.  </p>

<p>Customer journey maps are a type of blueprint used to orchestrate, illustrate and communicate a customer experience. They have become crucial assets in any digital transformation program due to their multitude of uses and often value beyond their immediate need. Much of their versatility lies in being flexible enough to expose the entire customer lifecycle or just one of their component parts to a developer or CEO alike.</p>

       
<h2>They can be used in many ways, such as:</h2>
<ul>
  <li>An early demonstration how a new product or service might work (through the lens of the customer).	</li>
  <li>Baseline an understanding of the current experience and identify pain points therein </li>
  <li>Provide an understanding of the how an experience could differ between different customer archetypes (or personas)</li>
  <li>Provide an understanding of the different channels involved in delivering the customer experience.  </li>
  <li>Flipping or elaborating a business process to show how a customer interacts with the process  </li>
  <li>Help inform much of the content required to support the customers experience</li>
  <li>Communicate how the customer feels or is affected (this is especially useful) at stages during the experience, highlighting the emotional impacts therein. </li>
  <li>To orchestrate the customer interactions in a customer experience and unify them with the business process </li>
  <li>Co-designing the customer experience with the customer, client or other stakeholders.  </li>
  <li>Anchor diverse sets stakeholders around the dimensions of a product service transformation    </li>

</ul>
<h2>How you’ll get value from them. </h2>
<p>First and foremost they set an aspiration for what the team want to deliver to the customer, removed from the constraints and inevitable compromises that will come into play later down the track. Conversely, they can show how a current constraint is impacting customers today.</p>

<p>They unify the team around the customer and their needs and your ideas for a solution in early stages of a project. This is especially useful for new (or transformations) products or service as a tool to iterate parts of the experience before a design prototype. </p>

<p>As a part of customer research, pinpointing the moments of truth giving you get a deeper understanding of a problem and opportunity.  </p>

<p>If you go on to dive into a service design blueprint the customer journey can be used to show the customer in context of the people, processes and platforms used to orchestrate the experience.</p>

<p>If you’re procuring any platforms and services, customer journey maps are a great addition to an RFP/Q to illustrate what some of your requirements might be, your prospective partners will be grateful and hopefully provide a clear response. </p>

          
  
      </section>
  
    </article>

    <Footer />
  </Layout>
);

export default IndexPage;
